import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";

import CreateBusiness from "./pages/CreateBusiness";
import Layout from "./components/layout/layout";



function App() { 
    return (
        <HashRouter >

          <Routes>
              <Route element={<Login />} path="/" />
              <Route element={<Layout><Dashboard /></Layout> } path="/t/dashboard" />
              <Route element={<Layout> <CreateBusiness /></Layout>} path="/t/createbusiness" />
          </Routes>
          </HashRouter>

    );
 }

export default App;

