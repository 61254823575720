import React from 'react'

import VDiv from './vdiv'
import Text from '../elements/texts/text'

export default function Form({title, subTitle, children, className, showLogo, onSubmit, validated}) {
    return (
        <form validated={validated} onSubmit={onSubmit} >
        <VDiv className={`form-container w-100 ${className ? className : ''}`}>
            <VDiv className="d-flex align-items-center flex-column">
                {/* {showLogo ? <img className="form-logo mb-3" src="../assets/images/G-ecommerce-logo.svg" alt="Sree Gokulam Hospital" />: null} */}
                <Text text={title} size="lg" className="mb-3 block" />
                <Text text={subTitle} size="md" className="mb-3 block" />
                <VDiv className="row w-100">
                    {children}
                </VDiv>
            </VDiv>
        </VDiv>
        </form>
    )
}