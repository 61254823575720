import React from "react";
import Card from "../components/cards/card";
import Form from "../components/containers/form";
import HDiv from "../components/containers/hdiv";
import Button from "../components/inputs/button";
import InputGroup from "../components/inputs/input-group";
import SelectGroup from "../components/inputs/select-group";

export default function CreateBusinessForm(props) {

    const type = [{name:"RESIDENTIAL", key:"RESIDENTIAL"},{name:"OFFICE", key:"OFFICE"},{name:"SHIPPING", key:"SHIPPING"},{name:"BILLING", key:"BILLING"},{name:"COMMUNICATION", key:"COMMUNICATION"}]
    return (
        <>
        <Card title="Business Management " className="px-0"> 
            <Form  onSubmit={(event) => { event.preventDefault(); props.handleSubmit() }} >
                {/* <div class="alert alert-success" id="success-alert">
                    <strong>Success! </strong> Business Created.
                </div> */}

                <HDiv className="flex-column">
                    <h5>Create Business</h5>
                    <h5>Pleace enter below details to create business</h5>
                    <HDiv className="flex-wrap grid-container gap-3">
                    <InputGroup className="mb-3 col-md-12 col-xl-5 col-sm-12 flex-grow-1" label="Business name" name="name" placeHolder="Enter business name" type="text" onChange={props.handleInputChange} required/>
                    <InputGroup className="mb-3 col-md-12 col-xl-5 col-sm-12 flex-grow-1" label="Business description" name="description" placeHolder="Enter business description" type="text" onChange={props.handleInputChange} required />
                    <SelectGroup className="mb-3 col-md-12 col-xl-5 col-sm-12 flex-grow-1" label="Type" type="text" name="type" placeHolder="Enter business type" onChange={props.handleInputChange} list={type} required />
                    <InputGroup className="mb-3 col-md-12 col-xl-5 col-sm-12 flex-grow-1" label="Address Line 1" name="addressLine1" placeHolder="Enter business address" type="text" onChange={props.handleInputChange} required />
                    <InputGroup className="mb-3 col-md-12 col-xl-5 col-sm-12 flex-grow-1" label="Address Line 2" name="addressLine2" placeHolder="Enter business address" type="text" onChange={props.handleInputChange} required />
                    <InputGroup className="mb-3 col-md-12 col-xl-5 col-sm-12 flex-grow-1" label="City" name="city" type="text" placeHolder="Enter city here" onChange={props.handleInputChange} required />
                    <InputGroup className="mb-3 col-md-12 col-xl-5 col-sm-12 flex-grow-1" label="State" name="state" type="text" placeHolder="Enter State here" onChange={props.handleInputChange} required />
                    <InputGroup className="mb-3 col-md-12 col-xl-5 col-sm-12 flex-grow-1" label="Country" name="country" type="text" placeHolder="Enter country here" onChange={props.handleInputChange} required />
                    <InputGroup className="mb-3 col-md-12 col-xl-5 col-sm-12 flex-grow-1" label="Pincode" name="pinCode" type="number" placeHolder="Enter pincode here" onChange={props.handleInputChange} required />
                    <div className="mb-3 col-md-12 col-xl-5 col-sm-12 flex-grow-1"></div>
                    </HDiv>
                </HDiv>
                <HDiv className="justify-content-center mb-2 " >
                    <Button className=" w-100 desk-btn" label="Submit" type="submit" />
                </HDiv>


            </Form>
            </Card>
        </>
    )
}
