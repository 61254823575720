import React from "react";

import useDashboard from "../hooks/useDashboard";
// import EditButton from "../components/inputs/edit-button";
import DeleteButton from "../components/inputs/delete-button";
import DataTable  from "react-data-table-component";
import Card from "../components/cards/card";
import useMenu from "../hooks/useMenu";
import HDiv from "../components/containers/hdiv";

export default function Dashboard() {  
  const {handleScroll} = useMenu()
    const {list, handleAdd, handleDelete,setSearchValue} = useDashboard({handleScroll})
    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            fixed:"left"
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true,
        },
        {
          name: 'Email',
          selector: row => row.contactEmail,
          sortable: true,
      },
        // {
        //     name:"Edit",
        //     selector: row => <EditButton onClick={()=>handleEdit(row)}/>,
        //     width:"100px"
        // },
        {
            name:"Delete",
            selector: row => <DeleteButton onClick={()=>handleDelete(row)} />,
            width:"100px"
        },
    ];
  return (
      <>
        <Card title="Dashboard" subTitle="Add/Edit/Delete your categories." modalId="masterTypeModal" showAddButton onAdd={()=>handleAdd()} addLabel="Add Product"> 
        <HDiv className={"w-100 justify-content-end"}>
          <input className={"col-3 mb-3 search"} type="search"placeHolder={"Search by Name"} onChange={(event)=>setSearchValue(event.target.value)}/>
        </HDiv>
          <DataTable columns={columns}  data={list} actions pagination /> 
        </Card>
      </>
  );
}
