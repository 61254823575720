import React from "react";
import { useCreateBusiness } from "../hooks/useCreateBusiness";
import CreateBusinessForm from "./CreateBusinessForm";
import CreateBusinessUserForm from "./CreateBusinessUserForm";
import useDashboard from "../hooks/useDashboard";
import useMenu from "../hooks/useMenu";

export default function CreateBusiness() {
    const {show, setShow,details} = useDashboard({})
    const {handleScroll} = useMenu()
    const { handleInputChange, handleSubmit, handleUserInputChange, handleBusinessUser, showUserForm} = useCreateBusiness({show, setShow, details,handleScroll})
    return(
        // <Layout>
        <div className="business-form">
            {!showUserForm &&
            <CreateBusinessForm handleSubmit={handleSubmit} handleInputChange={handleInputChange} />}
            {showUserForm &&
            <CreateBusinessUserForm handleSubmit={handleBusinessUser} handleUserInputChange={handleUserInputChange}/>}
        </div>
        // </Layout>
    )
}