import { CREATE_ADDRESS_OF_BUSINESS, CREATE_NEW_BUSINESS, CREATE_NEW_BUSINESS_USER, DELETE_USER_URL, GET_ALL_BUSINESS_URL, REGISTER_BUSINESS_USER, USER_LOCATION, createUserLocation, findByEmailUrl } from "./ApiUrls";
import { post, get, Delete, Post, Put, Get } from "./Service";


export const getAllBusinessDatas = async () => {
    const response = await get(GET_ALL_BUSINESS_URL);
    if(!response) {
        return []
    }
    return response;
};

export const createBusinessAddress = async (data) => {
    const response = await post(CREATE_ADDRESS_OF_BUSINESS,data);
    if(!response) {
        return []
    }
    return response;
};

export const getByAddressId = async (data) => {
    const response = await Get(`${CREATE_ADDRESS_OF_BUSINESS}/${data}`);
    if(!response) {
        return []
    }
    return response;
};

export const updateBusinessAddress = async (data) => {
    const response = await Put(CREATE_ADDRESS_OF_BUSINESS,data);
    if(!response) {
        return []
    }
    return response;
};
export const createNewBusiness = async (data) => {
    const response = await post(CREATE_NEW_BUSINESS,data);
    if(!response) {
        return []
    }
    return response;
};

export const createBusinessUser = async (data) => {
    const response = await post(CREATE_NEW_BUSINESS_USER,data);
    if(!response) {
        return []
    }
    return response;
};

export const registerBusinessUser = async (data) => {
    const response = await post(REGISTER_BUSINESS_USER,data);
    if(!response) {
        return []
    }
    return response;
};

export const deleteUser = async (userId) => {
    const response = await Delete(DELETE_USER_URL(userId));
    return response;
};

export const updateUser = async (data) => {
    const response = await Post(CREATE_NEW_BUSINESS_USER,data);
    return response;
};

export const createLocation = async (tenantid,data) => {
    const resp = await Post(createUserLocation(tenantid), data);
    return resp;
  };

  
export const createUserLocationBy = async (data) => {
    const resp = await Post(USER_LOCATION, data);
    return resp;
  };


  export const findEmail = async (email) => {
    try{
        const resp = await Get(findByEmailUrl(email));
        return resp;
    }catch(err){
        console.log(err);
    }
   
  };