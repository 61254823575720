import React from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "../general/alert";
/*eslint-disable */
export default function Header() {
    const navigate = useNavigate();

    const logout = () => {
        localStorage.clear();
        navigate("/");
        toast("success","Logout Successfull")

    };
  
    const handleSidebar = ()=>{
        document.querySelector(".g-sidenav-show").classList.add("g-sidenav-pinned")
    }

    return (
        
      <nav className="navbar navbar-main navbar-expand-lg px-0 mx-2 shadow-none border-radius-xl " id="navbarBlur" navbar-scroll="true">
      <div className="d-flex px-3 py-1 justify-content-between w-100 mt-4">
      <div className=" d-xl-none d-flex align-items-center order-1 me-5  ">
              <a href className="text-body p-0" id="iconSidenav" onClick={()=>handleSidebar()}  >
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line"></i>
                  <i className="sidenav-toggler-line"></i>
                  <i className="sidenav-toggler-line"></i>
                </div>
              </a>
           </div>
        <div className="ms-md-auto pe-md-3 d-xl-flex align-items-center  order-xl-1 order-2">
              <div className="input-group  order-2">
                {/* <span className="input-group-text text-body"><i className="fas fa-search" aria-hidden="true"></i></span>
                <input type="text" className="form-control" placeholder="Type here..." /> */}
              </div>
        </div> 
        <div className="collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4 order-xl-2 order-3" id="navbar">
          <ul className="navbar-nav justify-content-end w-100">
            <li className="nav-item d-flex align-items-center px-2 order-4 cursor-pointer">
              <a href className="nav-link text-body font-weight-bold px-0" id="userProfile" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="fa fa-user me-sm-1"></i>
              </a>
              <ul className="dropdown-menu  dropdown-menu-end position-absolute  px-2 py-2 mt-1" aria-labelledby="userProfile">
                <li className="mb-2">
                  <a className="dropdown-item border-radius-md" href="javascript:;">
                    <div className="d-flex py-1">
                      <div className="d-flex flex-column justify-content-center">
                        <h6 className="text-sm font-weight-normal mb-1">
                          <span className="font-weight-bold">{`${localStorage.getItem("firstName")} ${localStorage.getItem("lastName")}`}</span>
                        </h6>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mb-2" onClick={()=>logout()}>
                  <a className="dropdown-item border-radius-md" href="javascript:;">
                    <div className="d-flex py-1">
                      <div className="d-flex flex-column justify-content-center">
                        <h6 className="text-sm font-weight-normal mb-1">
                          <span className="font-weight-bold">Logout</span>
                        </h6>
                      </div>
                    </div>
                  </a>
                </li> 
              </ul>
            </li>
            
            <li className="nav-item px-3 d-flex align-items-center order-2">
              <a href className="nav-link text-body p-0">
                {/* <i className="fa fa-cog fixed-plugin-button-nav cursor-pointer"></i> */}
              </a>
            </li>
            <li className="nav-item dropdown pe-2 d-flex align-items-center order-3">
              <a href className="nav-link text-body p-0" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                <span className="font-weight-bold">{`${localStorage.getItem("firstName").toUpperCase()} ${localStorage.getItem("lastName").toUpperCase()}`}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    );
}
