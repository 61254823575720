import React from "react";

export default function InputGroup({ className, enabled = true, label, name, value, type, onChange, placeHolder, required,id, inputMode }) {

    return (
        <div className={className + " edc-input-group"} >
            
            <label className="form-label">{label}</label>
            <input className="form-control text-input" disabled={!enabled} name={name} id={id} onChange={(event) => onChange(event)} type={type} value={value} placeholder={placeHolder} required={required} inputMode={inputMode} />
        </div>
    );
}
