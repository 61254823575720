import React from "react";

import HGrow from "../components/elements/h-grow"
import HDiv from "../components/containers/hdiv"
import Text from "../components/elements/texts/text"
import VDiv from "../components/containers/vdiv"

import Button from "../components/inputs/button"
import InputGroup from "../components/inputs/input-group"
import useLogin from "../hooks/useLogin"
import Form from "../components/containers/form"

export default function Login() {
    const { handleLogin, setPassword, setUserName, handleEye } = useLogin()

    return(
      <HDiv className="page-form-container login">
            <VDiv className="page-form-banner">
                <VDiv className="sliders">
                <VDiv className="slider slider1"></VDiv>
                </VDiv>
            </VDiv>
            <div className=" d-xl-flex justify-content-center align-items-xl-center align-items-end">
            <Form showLogo title="Welcome Super Admin" subTitle="Sign in to your account" onSubmit={(event)=>{event.preventDefault(); handleLogin()}}> 
                <InputGroup className="mb-3 col-md-12" enabled label="Enter your username" name="email-username" onChange={(event) => setUserName(event.target.value)} type="text" placeHolder={"Email"} required/>
                <VDiv className="position-relative w-100 p-0">
                    <InputGroup className="mb-3 col-md-12" enabled label="Enter your password" name="password" id="togglepassword" onChange={(event) => setPassword(event.target.value)} type="password" placeHolder={"********"} required/>
                    <i
              class="eye-icon far fa-eye-slash"
              id="eye"
              onClick={(event) => handleEye(event)}
            ></i>
                </VDiv>
                <HDiv className="flex mb-3 no-padding">
                    {/* <label className="edu-checkbox"><input type="checkbox"/> Remember me</label> */}
                    <HGrow />
                    <Text text="Forgot password?" className="edu-link underline" />
                </HDiv>
                <Button className="mb-3 col-md-12" label="Sign in"  type="submit"/>
                {/* <Text text="Don't have an account?"><a href="#/signup" className="edu-link underline">Sign up</a></Text> */}
            </Form>
            </div>
        </HDiv>
    )
}