import { useState } from "react"
import { createBusinessAddress, createBusinessUser, createNewBusiness, findEmail, registerBusinessUser } from "../service/MasterServices"
import { toast } from "../components/general/alert"


export const useCreateBusiness = ({show, setShow, details,handleScroll}) =>{
    const [formData, setFormData] = useState(undefined)
    const newData = {name:"", description:"", addressId:"", addressLine1:"", addressLine2:"", city:"",state:"",pinCode:"",country:"",type:""}
    const newUserData = { firstName:"",lastName:"",email:"",token:"",userType:"",phone:""}
    const [userDetails, setUserDetails]= useState(newUserData)
    const [data, setData] = useState(formData !== undefined ? {...formData} : {...newData})
    const [ tenantId, setTenantId] = useState("")
    const [ address, setAddress] = useState()
    const [ userId, setUserId] = useState("")  
    const [ showUserForm, setShowUserForm] = useState(false)
    const $ = require('jquery')
    
   
    // useEffect(()=>{
    //     $(document).ready(function() {
    //         $("#success-alert").hide();
    //     })
    // },[])

    const handleInputChange = (event) => {
        event.stopPropagation()
        setData((state) => ({
            ...state,
            [event.target.name]: event.target.value
        }));
    }

    const handleUserInputChange= (event) =>{
        event.stopPropagation()
        setUserDetails((state) => ({
            ...state,
            [event.target.name]: event.target.value
        }));
    }

    const handleClear = ()=>{
        setData(newData)
        setShowUserForm(false)
        setUserDetails(newUserData)
    }

    const handleSubmit = async () => {
        setShowUserForm(true)
      
       
    }

    const handleBusinessUser = async()=>{
        const email = await findEmail(userDetails.email)
        console.log(email);
        if(email && email.data !== "The Given Mail Id Exist"){
            let addressData = { addressLine1: data.addressLine1, addressLine2: data.addressLine2, city: data.city, state:data.state, pincode: data.pinCode, country: data.country, type: data.type}
            const response = await createBusinessAddress( addressData)
            console.log(response)
            if(response && response.status === 200 ){
                setAddress(response.data.id)
                let datas = {
                    name:data.name,
                    description:data.description,
                    addressId:response.data.id, 
                    contactEmail:userDetails.email,
                    contactPhone: userDetails.phone,
                    businessType: null
                }
                const result = await createNewBusiness(datas)
                console.log(result)
                if(result && result.status === 200){ 
                    setTenantId(result.data.id)
                    const res = await createBusinessUser(userDetails)
                    if(res && res.status === 200){
                    setUserId(res.data.id)
                    let data = {
                        tenantId : result.data.id,
                        userId : res.data.id
                    }
                        const register = await registerBusinessUser(data)
                        console.log(register);
                        handleClear()
                        toast("success","Created Successfully")
                        $(document).ready(function() {
                            $(function showAlert() {
                              $("#success-alert").fadeTo(2000, 500).slideUp(500, function() {
                                $("#success-alert").slideUp(500);
                              });
                            });
                          });
                }
            }
              }
        }else {
            toast("error", "Email already exist")
        }
      
    }

    return{formData,data , handleInputChange, handleSubmit, handleUserInputChange, userDetails, handleBusinessUser , showUserForm, setFormData,userId, setUserId, tenantId, address}
}