import { useState, useEffect } from "react";
// import { useParams } from "react-router-dom"


export default function useMenu(){

    const [items, setItems] = useState([])

    // const { tenantId, locationId } = useParams();

    // const [show , setShow ] = useState(true)
    const handleSidebar = ()=>{
          document.querySelector(".g-sidenav-show").classList.remove("g-sidenav-pinned")
      }

    const generateMenus = (masterTypes) => {
        let items = []
        items.push({title: "Dashboard", link: `#/t/dashboard`, icon: "bx-home-circle", type: "menu", active: true})
        items.push({title: "Create Business", link: `#/t/createbusiness`, icon: "bxs-cog", type: "menu"})
       

        setItems(items)
    }
    useEffect(() => {
        generateMenus()
    }, [])

    const handleScroll = (event) =>{  
        localStorage.setItem("menuName", event.target.id)
        let items = document.getElementsByClassName("nav-link")
        var current = document.getElementsByClassName("active");
        for(let i=0; i < items.length; i++){
            if(items[i].id === localStorage.getItem("menuName")){
                current[0].className = current[0].className.replace(" active", "");
                items[i].classList.add("active")
            }
        }      
        
    }

    return {items, handleSidebar, handleScroll}
}