import React from "react";
import Card from "../components/cards/card";
import Form from "../components/containers/form";
import HDiv from "../components/containers/hdiv";
import Button from "../components/inputs/button";
import InputGroup from "../components/inputs/input-group";
import SelectGroup from "../components/inputs/select-group";

export default function CreateBusinessUserForm(props) {

    const type = [{name:"ADMIN", key:"ADMIN"}]
    return (
        <>
        <Card title="Business Management" className="px-0">
            <Form  onSubmit={(event) => { event.preventDefault(); props.handleSubmit() }}>

                
                <HDiv className="flex-column">
                {/* <VDiv className="text-left ms-4">
                    
                </VDiv > */}
                    <h3>Create user</h3>
                    <h5>Pleace enter below details to create user</h5>
                    <HDiv className="flex-wrap grid-container gap-3">

                    <InputGroup className="mb-3 col-md-12 col-xl-5 col-sm-12 flex-grow-1" label="First name" name="firstName" placeHolder="Enter first name" type="text" onChange={props.handleUserInputChange} required />
                    <InputGroup className="mb-3 col-md-12 col-xl-5 col-sm-12 flex-grow-1" label="Last name" name="lastName" placeHolder="Enter last name" type="text" onChange={props.handleUserInputChange} required />
                    <InputGroup className="mb-3 col-md-12 col-xl-5 col-sm-12 flex-grow-1" label="Email" name="email" placeHolder="Enter email" type="text" onChange={props.handleUserInputChange} required />
                    <InputGroup className="mb-3 col-md-12 col-xl-5 col-sm-12 flex-grow-1" label="Enter new password" name="token" placeHolder="Enter new password" type="text" onChange={props.handleUserInputChange} required />
                    <InputGroup className="mb-3 col-md-12 col-xl-5 col-sm-12 flex-grow-1" label="Phone No" name="phone"  placeHolder="Enter phone number" type="text"  onChange={props.handleUserInputChange} required inputMode={"numeric"} />
                    <SelectGroup className="mb-3 col-md-12 col-xl-5 col-sm-12 flex-grow-1" label="User type" type="text" name="userType" placeHolder="Select user type" onChange={props.handleUserInputChange} list={type} required />
                    </HDiv>
                </HDiv>
                <HDiv className="justify-content-center mb-2">
                    <Button className="w-100 desk-btn" label="Submit" type="submit" />
                </HDiv>
            </Form>
            </Card>
        </>
    )
}
