import axios from "axios";

import { Initialize } from "./Interceptor";

Initialize();

export const getAuthorization = () => `Bearer ${localStorage.getItem("token")}`;

export const getHeader = (type) => {
  const headers = {
    "Content-Type": type ? type : "application/json; charset=utf-8",
    'Authorization': getAuthorization()
  };
  return { headers };
};


// Post
export async function post(url, data, headers) {
    const response = await axios.post(url, data, headers ? { headers } : getHeader());
    console.log(response)
    if (response && response.data && response.status === 200) {
      return response;
    }
    return response;
}

export async function Post(url, data, headers) {
  const response = await axios.post(url, data, headers ? { headers } : getHeader());
  return response;
}

// Get
export async function get(url, headers) {
    const response = await axios.get(url, headers ? { headers } : getHeader());

    if (response && response.data) {
      return response.data;
    }else {
      return undefined;
    }
}

export async function Get(url, headers) {
  const response = await axios.get(url, headers ? { headers } : getHeader());
    return response;
}

// Put
export async function put(url, data, headers) {
    const response = await axios.put(url, data, headers ? { headers } : getHeader());

    if (response && response.data && response.status === 200) {
      return response.data;
    }
    return response;
}

export async function Put(url, data, headers) {
  const response = await axios.put(url, data, headers ? { headers } : getHeader());
  return response;
}

// Delete
export async function Delete(url, headers) {
    const response = await axios.delete(url, headers ? { headers } : getHeader())
    return response;
}

export async function upload(url, data) {
  try {
    const response = await axios.post(url, data, getHeader('multipart/form-data'))
    if (response && response.data && response.status === 200) {
      return response.data;
    }else if (response.status === 201) {
      return true;
    }
  } catch (err) {

  }
}