import { useEffect, useState } from "react";
import { deleteUser, getAllBusinessDatas } from "../service/MasterServices";
import { useNavigate } from "react-router";
import { toast } from "../components/general/alert";

export default function useDashboard({handleScroll}) {

    const [list, setList] = useState([])
    
    const [show, setShow] = useState(false)

    const [searchValue, setSearchValue] = useState("")

    const [details, setDetails] = useState({})

    const navigate = useNavigate()

    const getHeaders = () => {
      const headers = [
          {title: "Name", type: "label", property: "name"},
          {title: "Description", type: "label", property: "description"},
          {title: "Action", type: "action"}
      ]
      return headers;
  }


    const fetchAll = async () => {
        const response = await getAllBusinessDatas()
        let newList = []
        for(let i=0; i<response.length ; i++){
          if(response[i].deleted === false){
            newList.push({...response[i]})
          }
        
        }
        setList(newList)
        // setLoading(false)
    }

    const handleAdd = () => {
      handleScroll({target:{id:"menuItem1"}})
      navigate("/t/createbusiness")
     
  }

  const handleDelete = async(row) =>{
    const response = await deleteUser(row.id);
    if(response && response.status === 200){
      toast("success","Deleted Successfull")
      fetchAll()
    }

  }

  const handleEdit = async(row) =>{
    handleScroll({target:{id:"menuItem1"}})
    navigate("/t/createbusiness")
  }

  useEffect(()=>{
    fetchAll()
  },[])

  useEffect(()=>{
    const fetchData = async()=>{
      const response = await getAllBusinessDatas()
      let newList = []
      for(let i=0; i<response.length ; i++){
        if(response[i].deleted === false){
          newList.push({...response[i]})
        }
      
      }
      const filtered = newList.filter(person => person.name.toLowerCase().includes(searchValue.toLowerCase()));
      setList(filtered)
    }
   fetchData()
//eslint-disable-next-line
},[searchValue])

  return{list, getHeaders, handleAdd, handleDelete, handleEdit, show, setShow,details, setDetails,setSearchValue}
}